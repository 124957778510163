import { Link } from 'gatsby';
import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import Chev from '../images/svgs/chevron.svg';
import { motion } from 'framer-motion';

class HeaderCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: false,
      lazyLoad: true,
      infinite: true,
      speed: 700,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false
    };
    const StyledCarousel = styled.div`
      overflow: hidden;
      position: relative;
    `;

    const StyledSliderItem = styled(motion.div)`
      outline: 0;
      position: relative;
      z-index: 5;
      height: 65rem;
      background-image: url(${props => props.bg});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        overflow: hidden;
      }
      .container {
        height: 100%;
        .engineer {
          max-height: 90%;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 45%;
          margin: auto;
          object-fit: contain;
        }
        .slider-content {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          .main-box {
            margin-right: 5rem;
            position: relative;
            background: rgb(255, 119, 0);
            background: linear-gradient(
              45deg,
              rgba(255, 119, 0, 1) 0%,
              rgba(165, 78, 1, 1) 100%
            );
            color: #ffffff;
            width: 40%;
            padding: 6rem 4rem;
            h2 {
              font-size: 3.3rem;
              margin: 1rem 0;
            }
            p {
              color: #e4e4e4;
              font-size: 1.6rem;
              font-family: ${props => props.theme.fonts.sec};
              line-height: 2rem;
              text-align: justify;
            }
            a {
              color: #ffffff;
              background: ${props => props.theme.colors.grey2};
              padding: 1.8rem 2.5rem;
              text-decoration: none;
              display: inline-block;
              margin-top: 1rem;
              font-size: 1.5rem;
              letter-spacing: 0.1rem;
              box-shadow: 0px 3px 4px #444444;
              &:hover {
                box-shadow: 0px 2px 4px #444444;
              }
            }
            .photo-box {
              background-image: url(${props => props.bg});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              border: 3px solid ${props => props.theme.colors.org};
              height: 15rem;
              width: 40%;
              position: absolute;
              right: -5rem;
              bottom: -5rem;
            }
            .carousel-controlers {
              position: absolute;
              left: 4rem;
              bottom: -2.5rem;
              button {
                padding: 1.2rem;
                margin-right: 0.5rem;
                border: 0;
                background: ${props => props.theme.colors.grey2};
                cursor: pointer;
                outline-color: ${props => props.theme.colors.grey};
                box-shadow: 0px 2px 2px #444444;
                svg {
                  fill: #ffffff;
                  width: 35px;
                  height: 30px;
                }
              }
              .go-back {
                svg {
                  transform: scaleX(-1);
                }
              }
            }
          }
        }
      }
      @media (max-width: 1024px) {
        .container {
          .engineer {
            height: 100%;
            object-fit: fill;
          }
        }
      }
      @media (max-width: 450px) {
        height: 55rem;
        .container {
          width: 100%;
          .engineer {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
          .slider-content {
            align-items: flex-end;
            .main-box {
              padding: 4rem 3rem;
              width: 75%;
              margin: 0 auto 5rem;
              h2 {
                font-size: 2.2rem;
              }
              p {
                display: none;
              }
              a {
                font-size: 1.2rem;
              }
              .photo-box {
                display: none;
              }
              .carousel-controlers {
                right: 3rem;
                left: unset;
                svg {
                  width: 2.5rem;
                  height: auto;
                }
              }
            }
          }
        }
      }
    `;
    return (
      <StyledCarousel>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {this.props.slides.map((slide, i) => (
            <StyledSliderItem
              key={i}
              bg={slide.acf.background_photo.source_url}
            >
              <div className="container">
                <motion.img
                  initial={{ x: 80 }}
                  animate={{ x: 0 }}
                  transition={{ duration: 0.5 }}
                  className="engineer"
                  src={slide.acf.engineer_photo.source_url}
                  alt="enginner"
                />
                <motion.div
                  initial={{ opacity: 0, x: 80 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className="slider-content"
                >
                  <div className="main-box">
                    <h2>{slide.acf.sliderName}</h2>
                    <p>{slide.acf.slider_content}</p>
                    <Link to={slide.acf.read_more_link}>READ MORE</Link>
                    <div className="carousel-controlers">
                      <button
                        className="go-back"
                        aria-label="next"
                        onClick={this.previous}
                      >
                        <Chev />
                      </button>
                      <button
                        className="go-next"
                        aria-label="next"
                        onClick={this.next}
                      >
                        <Chev />
                      </button>
                    </div>
                    <div className="photo-box"></div>
                  </div>
                </motion.div>
              </div>
            </StyledSliderItem>
          ))}
        </Slider>
      </StyledCarousel>
    );
  }
}

export default HeaderCarousel;
