import styled from 'styled-components';

export const OurStats = styled.div`
  background: rgb(156, 157, 159);
  background: linear-gradient(
    45deg,
    rgba(156, 157, 159, 1) 0%,
    rgba(131, 132, 134, 1) 55%,
    rgba(115, 116, 117, 1) 100%
  );
  padding: 2.5rem;
  color: #ffffff;
  margin-bottom: 8rem;
  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .stat {
    .stat-top {
      display: flex;
      align-items: center;
      font-size: 5rem;
      svg {
        fill: #ffffff;
        margin-left: 1rem;
        width: 5rem;
        height: auto;
      }
    }
    .stat-bottom {
      font-size: 1.8rem;
      margin-top: 1rem;
    }
  }
  @media (max-width: 768px) {
    .container {
      flex-wrap: wrap;
      .stat {
        margin-bottom: 2rem;
      }
    }
  }
`;

export const SectionHeading = styled.div`
  color: ${props => props.theme.colors.grey2};
  font-size: 3rem;
  margin: 5rem 0;
  font-weight: bold;
  span {
    font-weight: 600;
    display: block;
    color: ${props => (props.white ? '#ffffff' : props.theme.colors.org)};
  }
`;

export const CompanyDetails = styled.div`
  margin: 12rem 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) and (min-width: 500px) {
    flex-wrap: wrap;
    margin: 12rem 0 7rem;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    margin: 12rem 0 0;
  }
`;

export const StyledDetailsBlock = styled.div`
  width: 31%;
  background: ${props => props.theme.colors.org};
  padding: 2rem 0;
  color: #ffffff;
  height: 33rem;
  position: relative;
  &#block-three {
    min-height: 33rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .block-content {
      height: auto;
    }
  }
  .block-photo {
    width: 85%;
    height: 21rem;
    border-bottom: 1.2rem solid ${props => props.theme.colors.grey2};
    margin: -8rem auto 1rem;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  .block-heading {
    font-size: 2.7rem;
    font-weight: bold;
    padding: 1rem 3rem 0;
    span {
      font-size: 2rem;
      font-weight: 600;
      display: block;
      margin-bottom: 0.2rem;
    }
  }
  .block-content {
    font-size: 1.6rem;
    padding: 0 3rem;
    font-family: ${props => props.theme.fonts.sec};
    line-height: 2rem;
    overflow: hidden;
    height: 8rem;
  }
  .pdfs {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  a {
    text-decoration: none;
    display: inline-block;
    color: #ffffff;
    padding: 1rem 2rem;
    background: ${props => props.theme.colors.org2};
    border-radius: 0.6rem;
    margin-bottom: 1rem;
    width: 85%;
    text-align: center;
  }
  button {
    cursor: pointer;
    outline: none;
    position: absolute;
    left: 50%;
    bottom: -5.5rem;
    transform: translate(-50%, -50%);
    padding: 1rem;
    border: 0;
    border-radius: 4rem;
    background: ${props => props.theme.colors.grey2};
    svg {
      width: 3.5rem;
      height: auto;
      fill: #ffffff;
    }
  }
  @media (max-width: 768px) and (min-width: 500px) {
    margin-bottom: 12rem;
    width: 48%;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 450px) {
    width: 100%;
    margin-bottom: 12rem;
  }
`;

export const StyledProjects = styled.div`
  margin-bottom: 10rem;
  .projects {
    display: flex;
    flex-wrap: wrap;
    .project {
      background: #333333;
      width: 23.5%;
      height: 30rem;
      margin: 0 0.6rem 1rem;
      position: relative;
      &:first-child,
      &:nth-child(6) {
        width: 50%;
      }
      &:first-child {
        margin-left: 0;
      }
      &:nth-child(4) {
        margin-left: 0;
      }
      .project-photo {
        height: 100%;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          overflow: hidden;
          z-index: 1;
        }
        .gatsby-image-wrapper {
          height: 100%;
        }
      }
      .project-details {
        position: absolute;
        bottom: 3rem;
        left: 2rem;
        z-index: 2;
        color: #ffffff;
        a {
          color: ${props => props.theme.colors.org};
          text-decoration: none;
          font-size: 1.2rem;
          margin-top: 0;
        }
        h2 {
          margin-bottom: 0.5rem;
          a {
            color: #ffffff;
            font-size: 1.8rem;
            &:hover {
              color: ${props => props.theme.colors.org};
            }
          }
        }
      }
    }
  }
  .more-projects {
    display: inline-block;
    text-decoration: none;
    margin-top: 3rem;
    border: 2px solid ${props => props.theme.colors.org};
    padding: 1.8rem 5rem;
    font-size: 1.6rem;
    border-radius: 3rem;
    transition: 0.3s all;
    color: ${props => props.theme.colors.grey2};
    &:hover {
      background: ${props => props.theme.colors.org};
      color: #ffffff;
    }
  }
  @media (max-width: 1024px) {
    .projects .project {
      height: 26rem;
      &:first-child,
      &:last-child {
        width: 49%;
      }
    }
  }
  @media (max-width: 768px) {
    .projects {
      overflow-x: auto;
      flex-wrap: nowrap;
      .project {
        width: 55%;
        flex: 0 0 auto;
        &:first-child {
          width: 55%;
        }
      }
    }
  }
  @media (max-width: 450px) {
    .projects .project {
      height: 22rem;
      width: 75%;
      &:first-child {
        width: 75%;
      }
    }
  }
`;

export const StyledNews = styled.div`
  background: ${props => props.theme.colors.org};
  padding: 2rem 0 5rem;
  margin-bottom: 10rem;
  .news {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .news-block {
      width: 32.5%;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(68, 68, 68, 0.4);
      margin-bottom: 3rem;
      .photo {
        height: 25rem;
        .gatsby-image-wrapper {
          height: 100%;
        }
      }
      h2 {
        a {
          display: inline-block;
          text-decoration: none;
          color: ${props => props.theme.colors.org};
          padding: 1.5rem 2.5rem 0.3rem;
          font-size: 1.8rem;
          &:hover {
            color: #111111;
          }
        }
      }
      p {
        padding: 0 2.5rem;
        font-family: ${props => props.theme.fonts.sec};
        font-size: 1.6rem;
        color: #111111;
      }
      .learn-more {
        margin-bottom: 2rem;
        display: inline-block;
        padding: 0 2.5rem;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-family: ${props => props.theme.fonts.pri};
        font-size: 1.4rem;
        color: ${props => props.theme.colors.org};
        &:hover {
          color: #111111;
        }
        svg {
          fill: #111111;
        }
      }
    }
  }
  .more-news {
    display: inline-block;
    text-decoration: none;
    margin-top: 3rem;
    border: 2px solid#ffffff;
    padding: 1.8rem 5rem;
    font-size: 1.6rem;
    border-radius: 3rem;
    transition: 0.3s all;
    color: #ffffff;
    &:hover {
      background: ${props => props.theme.colors.org2};
      border-color: ${props => props.theme.colors.org2};
      color: #ffffff;
    }
  }
  @media (max-width: 768px) {
    .news {
      overflow-x: auto;
      flex-wrap: nowrap;
      .news-block {
        width: 65%;
        margin-right: 1.5rem;
        flex: 0 0 auto;
      }
    }
  }
  @media (max-width: 450px) {
    .news .news-block {
      width: 85%;
    }
  }
`;

export const StyledOurCustomers = styled.div`
  width: 95%;
  margin: 0 auto 10rem;
  .slick-track {
    display: flex;
    justify-content: space-between;
  }
  .header {
    text-align: center;
    margin-bottom: 5rem;
    font-size: 2.8rem;
    color: ${props => props.theme.colors.grey2};
    span {
      color: ${props => props.theme.colors.org};
    }
  }
  .slick-slide {
    width: 10% !important;
    .partner {
      height: 10rem;
      .gatsby-image-wrapper {
        height: 100%;
        img {
          object-fit: contain !important;
        }
      }
    }
  }
  .controllers {
    margin: 5rem 0;
    display: flex;
    justify-content: center;
    button {
      padding: 1.2rem 3rem;
      border-radius: 0.5rem;
      font-size: 1.5rem;
      margin: 0 0.5rem;
      border: 0;
      display: flex;
      align-items: center;
      background: ${props => props.theme.colors.org};
      color: #ffffff;
      cursor: pointer;
      &:hover {
        background: ${props => props.theme.colors.grey2};
      }
      svg {
        fill: #ffffff;
      }
    }
    .previous {
      svg {
        transform: scaleX(-1);
      }
    }
  }
`;

export const StyledContactUs = styled.div`
  margin: 10rem 0;
  .header {
    text-align: center;
    margin-bottom: 5rem;
    font-size: 2.8rem;
    color: ${props => props.theme.colors.grey2};
    span {
      color: ${props => props.theme.colors.org};
    }
  }
  .content {
    position: relative;
    display: flex;
    margin-top: 8rem;
    .map {
      width: 75%;
      height: 52rem;
      background: ${props => props.theme.colors.org2};
      position: relative;
      border: 3px solid${props => props.theme.colors.org};
      .gatsby-image-wrapper {
        height: 100%;
      }
      .contact-details {
        position: absolute;
        bottom: 2rem;
        left: 2rem;
        z-index: 2rem;
        .box {
          background: ${props => props.theme.colors.org};
          margin-bottom: 1rem;
          padding: 2rem;
          text-align: center;
          font-size: 1.3rem;
          color: #ffffff;
          &.number {
            letter-spacing: 0.2rem;
          }
        }
      }
    }
    .form {
      width: 40%;
      height: 46rem;
      margin-top: 3rem;
      margin-left: -8rem;
      background: ${props => props.theme.colors.org};
      z-index: 2;
      position: absolute;
      right: 0;
      top: 0;
      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        input, textarea {
          padding: 1.8rem;
          font-size: 1.5rem;
          margin-bottom: 2rem;
          width: 80%;
          align-self: center;
          border: 0;
        }
        button {
          margin-left: 3rem;
          padding: 1.5rem 3rem;
          border: 0;
          font-size: 1.5rem;
          background: ${props => props.theme.colors.grey};
          color: #ffffff;
          cursor: pointer;
          &:hover {
            background: ${props => props.theme.colors.grey2};            
          }
        }
        span {
          margin-left: 3rem;
          margin-top: 1rem;
          font-size: 1.4rem;
          width: 90%;
        }
      }
      }
    }
  }
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
      overflow: hidden;
      .map {
        width: 90%;
        height: 30rem;
        margin: 0 auto 1rem;
        .contact-details .box {
          padding: 1.5rem;
          font-size: 1.2rem;
        }
      }
      .form {
        position: relative;
        width: 90%;
        margin: auto;
      }
    }
  }
`;
