import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import HeaderCarousel from '../components/HeaderCarousel';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  CompanyDetails,
  StyledDetailsBlock,
  OurStats,
  SectionHeading,
  StyledContactUs,
  StyledNews,
  StyledProjects
} from '../components/styles';
import ChevronDown from '../images/svgs/chevron-down.svg';
import ChevronRight from '../images/svgs/chevron.svg';
import EmployeeIcon from '../images/svgs/employees.svg';
import HappyIcon from '../images/svgs/happy.svg';
import YearIcon from '../images/svgs/years.svg';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const IndexPage = ({ data }) => {
  const [buttonMessage, setButtonMessage] = useState('Send Message');
  const [showMessage, setShowMessage] = useState(false);

  const openFullBox = box => {
    const boxDiv = document.getElementById(box);

    if (boxDiv.classList.contains('box-open')) {
      boxDiv.classList.remove('box-open');
    } else {
      boxDiv.classList.add('box-open');
    }
  };

  const [KnowMoreRef, KnowMoreInView] = useInView({
    rootMargin: '200px 0px'
  });

  const [LatestProjectsRef, LatestProjectsInView] = useInView({
    rootMargin: '200px 0px'
  });

  const [LatestNewsRef, LatestNewsInView] = useInView({
    rootMargin: '200px 0px'
  });

  const [ContactRef, ContactInView] = useInView({
    rootMargin: '200px 0px'
  });

  const onLeave = box => {
    const el = document.getElementById(box);
    if (el.classList.contains('box-open')) {
      setTimeout(() => el.classList.remove('box-open'), 3000);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();

    setButtonMessage('Sending..');

    const data = new FormData(e.target);

    const url =
      'https://script.google.com/macros/s/AKfycbzjk2Xy0fzLm79llwwOD2et5S0s3ERi2ebe48qIZMsMLDy_LAQN/exec';

    const res = await fetch(url, {
      method: 'POST',
      body: data
    });

    if (res.status === 200) {
      setShowMessage(true);
      setButtonMessage('Send Message');
    }
  };

  const renderProject = (project, i) => {
    const slug = project.acf.project_category.toLowerCase().replace('/', '-');
    return (
      <motion.div
        key={i}
        animate={{
          x: LatestProjectsInView ? 0 : 60,
          opacity: LatestProjectsInView ? 1 : 0
        }}
        transition={{ duration: 0.3 }}
        className="project"
      >
        <div className="project-photo">
          <Img
            fluid={project.acf.project_image.localFile.childImageSharp.fluid}
          />
        </div>
        <div className="project-details">
          <h2>
            <Link to={`/project/${project.slug}`}>
              {project.acf.project_name}
            </Link>
          </h2>
          <Link to={`/projects/${slug}`}>{project.acf.project_category}</Link>
        </div>
      </motion.div>
    );
  };

  return (
    <Layout>
      <SEO title="Home" />
      <HeaderCarousel slides={data.allWordpressAcfSlider.nodes} />
      <OurStats>
        <div className="container">
          <motion.div
            initial={{ x: -80 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
            className="stat"
          >
            <div className="stat-top">
              {data.wordpressWpHomepageContent.acf.years_in_the_field}+{' '}
              <YearIcon />
            </div>
            <div className="stat-bottom">Years in the field</div>
          </motion.div>
          <motion.div
            initial={{ x: -80 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="stat"
          >
            <div className="stat-top">
              {data.wordpressWpHomepageContent.acf.happy_clietns_number}+{' '}
              <HappyIcon />
            </div>
            <div className="stat-bottom">Happy Clients</div>
          </motion.div>
          <motion.div
            initial={{ x: -80 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="stat"
          >
            <div className="stat-top">
              {data.wordpressWpHomepageContent.acf.great_employees_number}+{' '}
              <EmployeeIcon />
            </div>
            <div className="stat-bottom">Great Employees</div>
          </motion.div>
        </div>
      </OurStats>
      <div className="container">
        <SectionHeading>
          <span>Know More</span>
          About Our Company
        </SectionHeading>
        <CompanyDetails ref={KnowMoreRef}>
          <StyledDetailsBlock
            onMouseLeave={() => onLeave('block-one')}
            id="block-one"
          >
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5 }}
              className="block-photo"
            >
              <Img
                fluid={
                  data.wordpressWpHomepageContent.acf.block_one_image.localFile
                    .childImageSharp.fluid
                }
              />
            </motion.div>
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="block-heading"
            >
              <span>
                {data.wordpressWpHomepageContent.acf.top_title_block_one}
              </span>
              {data.wordpressWpHomepageContent.acf.bottom_title_block_one}
            </motion.div>
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="block-content"
            >
              <p>{data.wordpressWpHomepageContent.acf.block_one_content}</p>
            </motion.div>
            <button onClick={() => openFullBox('block-one')}>
              <ChevronDown />
            </button>
          </StyledDetailsBlock>
          <StyledDetailsBlock
            onMouseLeave={() => onLeave('block-two')}
            id="block-two"
          >
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5 }}
              className="block-photo"
            >
              <Img
                fluid={
                  data.wordpressWpHomepageContent.acf.block_two_image.localFile
                    .childImageSharp.fluid
                }
              />
            </motion.div>
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="block-heading"
            >
              <span>
                {data.wordpressWpHomepageContent.acf.top_title_block_two}
              </span>
              {data.wordpressWpHomepageContent.acf.bottom_title_block_two}
            </motion.div>
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="block-content"
            >
              <p>{data.wordpressWpHomepageContent.acf.block_two_content}</p>
            </motion.div>
            <button onClick={() => openFullBox('block-two')}>
              <ChevronDown />
            </button>
          </StyledDetailsBlock>
          <StyledDetailsBlock id="block-three">
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5 }}
              className="block-photo"
            >
              <Img
                fluid={
                  data.wordpressWpHomepageContent.acf.block_three_image
                    .localFile.childImageSharp.fluid
                }
              />
            </motion.div>
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="block-heading"
            >
              <span>
                {data.wordpressWpHomepageContent.acf.top_title_block_three}
              </span>
              {data.wordpressWpHomepageContent.acf.bottom_title_block_three}
            </motion.div>
            <motion.div
              animate={{
                y: KnowMoreInView ? 0 : -30,
                opacity: KnowMoreInView ? 1 : 0
              }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="block-content"
            >
              <div className="pdfs">
                <Link to="/pdfs">Download Our Company Profile</Link>
              </div>
            </motion.div>
          </StyledDetailsBlock>
        </CompanyDetails>
        <SectionHeading>
          <span>Our</span>
          Latest Projects
        </SectionHeading>
        <StyledProjects>
          <div className="projects" ref={LatestProjectsRef}>
            {data.allWordpressWpProjects.nodes.map((project, i) =>
              renderProject(project, i)
            )}
          </div>
          <Link to="/projects" className="more-projects">
            More Projects
          </Link>
        </StyledProjects>
      </div>
      <StyledNews>
        <div className="container">
          <SectionHeading white>
            <span>Our</span>
            Latest News
          </SectionHeading>
          <div className="news" ref={LatestNewsRef}>
            {data.allWordpressWpNews.nodes.map((news, i) => (
              <motion.div
                key={i}
                animate={{
                  y: LatestNewsInView ? 0 : 40,
                  opacity: LatestNewsInView ? 1 : 0
                }}
                transition={{ duration: 0.3 }}
                className="news-block"
              >
                <div className="photo">
                  <Img
                    fluid={news.acf.news_image.localFile.childImageSharp.fluid}
                  />
                </div>
                <h2>
                  <Link to={`/news/${news.slug}`}>{news.acf.news_title}</Link>
                </h2>
                <p>{news.acf.excerpt}</p>
                <Link to={`/news/${news.slug}`} className="learn-more">
                  Learn More <ChevronRight />
                </Link>
              </motion.div>
            ))}
          </div>
          <Link className="more-news" to="/news">
            More News
          </Link>
        </div>
      </StyledNews>
      <div className="container">
        <StyledContactUs>
          <div className="header">
            <h2>
              <span>Contact</span> Us
            </h2>
          </div>
          <div className="content" ref={ContactRef}>
            <motion.div
              animate={{
                x: ContactInView ? 0 : 60,
                opacity: ContactInView ? 1 : 0
              }}
              transition={{ duration: 0.3 }}
              className="map"
            >
              <a
                href={data.wordpressAcfCompnayDetails.acf.map_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={
                    data.wordpressAcfCompnayDetails.acf.map_photo_homepage
                      .localFile.childImageSharp.fluid
                  }
                />
              </a>
            </motion.div>
            <motion.div
              animate={{
                x: ContactInView ? 0 : 60,
                opacity: ContactInView ? 1 : 0
              }}
              transition={{ duration: 0.3, delay: 0.2 }}
              className="form"
            >
              <form onSubmit={onSubmit}>
                <input type="text" name="name" placeholder="Name" required />
                <input type="email" name="email" placeholder="Email" required />
                <textarea
                  rows="6"
                  placeholder="Message"
                  name="message"
                  required
                ></textarea>
                <div>
                  <button type="submit">{buttonMessage}</button>
                </div>
                {showMessage && (
                  <span>
                    Message was successfully sent, We'll reply as soon as
                    possible.
                  </span>
                )}
              </form>
            </motion.div>
          </div>
        </StyledContactUs>
      </div>
    </Layout>
  );
};

export const data = graphql`
  {
    allWordpressAcfSlider {
      nodes {
        acf {
          sliderName
          slider_content
          read_more_link
          engineer_photo {
            source_url
          }
          background_photo {
            source_url
          }
        }
      }
    }
    wordpressWpHomepageContent {
      acf {
        block_one_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        block_two_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        block_three_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        years_in_the_field
        happy_clietns_number
        great_employees_number
        top_title_block_one
        bottom_title_block_one
        block_one_content
        top_title_block_two
        bottom_title_block_two
        block_two_content
        top_title_block_three
        bottom_title_block_three
      }
    }
    allWordpressWpProjects(limit: 6, sort: { order: DESC, fields: id }) {
      nodes {
        slug
        acf {
          project_name
          project_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          project_category
        }
      }
    }
    allWordpressWpNews(limit: 6, sort: { order: DESC, fields: id }) {
      nodes {
        slug
        acf {
          news_title
          excerpt
          content
          news_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfCompnayDetails {
      acf {
        map_link
        map_photo_homepage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 950) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
